export function authHeader() {
    // return authorization header with jwt token
    let userToken = localStorage.getItem('user-token');
    if (userToken) {
        return { 'Authorization': 'Bearer ' + userToken };
    } else {
        return {};
    }
}
export function getUserToken() {
  let token = localStorage.getItem('user-token');
  if (token !== 'undefined' && token) {
    return token;
  }
  return null;
}

export function setUserToken(token) {
  localStorage.setItem('user-token', token);
}

export function removeUserToken() {
  setUserToken(null);
  localStorage.removeItem('user-token');
}