const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }
  
const apiUrl = process.env.VUE_APP_API_URL

export {config, apiUrl}