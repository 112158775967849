import axios from 'axios'
import { authHeader } from '../helpers/authservice/auth-header'
import { apiUrl } from './config';
import { swalToast } from '../helpers/sweetalert2';


const api = axios.create({
  baseURL: `${apiUrl}/api`,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
})

api.defaults.withCredentials = true;
api.defaults.withXSRFToken = true;

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if(error.response?.status === 400){
      swalToast(`<h2 class="text-center mt-3">${error.response?.data?.message}</h2>`, "error", 4000, 'center', {
        width: '600px',
      })
    }
    if(error.response?.status === 422){
      swalToast(error.response?.data?.message, "error", 5000, 'center')
    }
    return Promise.reject(error)
  }
)

export const apiHttp = async (method, endpoint, data, options = {}) => {
  const headerAuth = authHeader()
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'es',
    'Access-Control-Allow-Origin': '*',
    ...headerAuth
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!options.hasOwnProperty('headers')) {
    // eslint-disable-next-line no-param-reassign
    options.headers = defaultHeaders
  }

  // eslint-disable-next-line no-prototype-builtins
  if (method === 'GET' && !options.hasOwnProperty('params')) {
    // eslint-disable-next-line no-param-reassign
    options.params = data
  }

  let serviceResponse = {}
  method = method.toLowerCase()
  const servicePromise = api({
    method,
    url: `${endpoint}`,
    data,
    ...options
  })
  try {
    const [materializedPromise] = await Promise.all([servicePromise])
    serviceResponse = { ...materializedPromise }
  } catch (error) {
    serviceResponse = error.response
  } 
  return serviceResponse
}
