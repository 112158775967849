import { defineStore } from "pinia";
import { apiHttp } from '../../api/axiosApi'
export const useGradeStore = defineStore("grade", {
    state: () => ({
        grades: []
    }),
    actions: {
        async getAllGrades() {
            try {
                const response = await apiHttp('GET', `/grades`)
                if (response.status === 200) {
                    this.grades = response.data
                    return response
                }
            } catch (error) {
                return error
            }
        },
    },
})