import { useAuthStore} from "@/state/pinia";
export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    redirect: "/list/visits",
  },
  {
    path: "/register/student",
    name: "register-student-public",
    meta: { title: "Autorizados a Retirar", authRequired: false, public: true },
    component: () => import("../views/public/FormRegisterAttorneysPublic")
  },
  {
    path: "/list/visits",
    name: "list-visits",
    meta: { title: "Visualización de retiros", authRequired: true },
    component: () => import("../views/withdrawalStudens/view/index")  
  },
  {
    path: "/report/visits",
    name: "report-visits",
    meta: { title: "Reporte de Retiros", authRequired: true },
    component: () => import("../views/maintainers/reportAttorneyVisits/index")
  },
  {
    path: "/register/visit",
    name: "register-visit",
    meta: { title: "Registrar retiro", authRequired: true },
    component: () => import("../views/withdrawalStudens/register/index")
  },
  {
    path: "/maintainers/users",
    name: "maintainers-users",
    meta: { title: "Mantenedor de Usuarios", authRequired: true },
    component: () => import("../views/maintainers/users/index")
  },
  {
    path: "/maintainers/students",
    name: "maintainers-students",
    meta: { title: "Mantenedor de Estudiantes", authRequired: true },
    component: () => import("../views/maintainers/students/index")
  },
  {
    path: "/maintainers/attorneys",
    name: "maintainers-attorneys",
    meta: { title: "Mantenedor de Apoderados", authRequired: true },
    component: () => import("../views/maintainers/attorneys/index")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: "Forgot password",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/pages/starter",
    name: "Starter",
    meta: { title: "Starter Page", authRequired: true },
    component: () => import("../views/utility/starter")
  },
];
