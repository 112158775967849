import { useLayoutStore } from "./pinia/layout";
import { useAuthFakeStore } from "./pinia/authFake";
import { useAuthStore } from "./pinia/auth";
import { useAttorneyStore } from "./pinia/attorney";
import { useNotificationStore } from "./pinia/notification";
import { useGradeStore } from "./pinia/grade";
import { useUserStore } from "./pinia/user";
import { useStudentStore } from "./pinia/student";
import { createPinia } from "pinia";

const pinia = createPinia();
export default pinia;

export { useLayoutStore, 
         useAuthFakeStore,
         useAuthStore,
         useNotificationStore,
         useAttorneyStore,
         useGradeStore,
         useUserStore,
         useStudentStore
       };
