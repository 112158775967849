import Swal from "sweetalert2";
export const swalToast = (message, type = "success", timer = 3000, position = 'top-end', options = {}) => {
  return Swal.fire({
    icon: type,    
    title: message,
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    ...options
  });
};