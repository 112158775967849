import { defineStore } from "pinia";
import { apiHttp } from '../../api/axiosApi'
import { removeUserToken, setUserToken } from "../../helpers/authservice/auth-header";
import { apiUrl } from "../../api/config";
export const useAuthStore = defineStore("auth", {
    state: () => ({
        currentUser: null,
    }),
    getters: {
        loggedIn() {
            return !!this.currentUser
        },
        currentUserInfo() {
            return this.currentUser
        }
    }
    ,
    actions: {
        async getSactumCsrf(){
            try {
                const response = await apiHttp('GET', `${apiUrl}/sanctum/csrf-cookie`)
                if (response.status === 200) {
                    return response
                }
            } catch (error) {
                return error
            }
        },
        async loginAction({ rut, password }) {
            try {
                await this.getSactumCsrf()
                const response = await apiHttp(
                    'POST',
                    '/login',
                    { rut, password },
                )
                if (response.status === 200) {
                    setUserToken(response.data.data.token)
                    await this.getProfileUser()
                    return response
                }
            } catch (error) {
                return error
            }
        },
        async getProfileUser(){
            try {
                const response = await apiHttp(
                    'GET',
                    '/user',
                    null
                );
                if (response.status === 200) {
                    this.currentUser = response.data.data
                }
            } catch (error) {
                return error
            }
        },
        async logoutAction() {
            try {
                const response = await apiHttp(
                    'POST',
                    '/logout',
                    null
                );
                if (response.status === 200) {
                    this.currentUser = null
                    removeUserToken()
                }
                return response
            }
            catch (error) {
                return error
            }
        },
    },
})