import { defineStore } from "pinia";
import { apiHttp } from '../../api/axiosApi'
export const useAttorneyStore = defineStore("attorney", {
    state: () => ({
        attorneys: {
            data: [],
            per_page: 10,
            total: 0
        },
        attorneyVisitsToday: {
            data: [],
            per_page: 10,
            total: 0
        },
        lastAttorneyVisitsTodayFromGrade: [],
        attorneyVisitsAll: {
            data: [],
            per_page: 10,
            total: 0
        }
    }),
    actions: {
        async getAttorneys(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/attorneys?${queryParams}`)
                if (response.status === 200 && !params.all) {
                    this.attorneys = response.data
                }
                return response
            } catch (error) {
                return error.response
            }
        },
        async createAttorney(data) {
            try {
                const response = await apiHttp('POST', `/attorneys`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async updateAttorney(id, data) {
            try {
                const response = await apiHttp('PUT', `/attorneys/${id}`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async deleteAttorney(id) {   
            try {
                const response = await apiHttp('DELETE', `/attorneys/${id}`)
                return response
            } catch (error) {
                return error
            }
        },   
        async attachStudentToAttorney(id, data) {
            try {
                const response = await apiHttp('POST', `/attorneys/${id}/attach/students`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async detachStudentFromAttorney(id, data) {
            try {
                const response = await apiHttp('DELETE', `/attorneys/${id}/detach/students`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async getAttorneyVisitsToday(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/attorney/visits/today?${queryParams}`)
                if (response.status === 200) {
                    this.attorneyVisitsToday = response.data
                    return response
                }
            } catch (error) {
                return error
            }
        },
        async registerVisitAttorney(data) {
            try {
                const response = await apiHttp('POST', `/register/attorney-visit`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async registerPublicAttorneys(data){
            const dataFormatted = {
                ...data,
                attorneys: data.attorneys.filter(attorney => attorney)
            }
            try {
                const response = await apiHttp('POST', `/attorneys/register/public`, dataFormatted)
                return response
            } catch (error) {
                return error
            }
        },
        async getAttorneyVisitsAll(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/attorney/visits/all?${queryParams}`)
                if (response.status === 200 && !params.all) {
                    this.attorneyVisitsAll = response.data
                }
                return response
            } catch (error) {
                return error.response
            }
        },      
        async getLastAttorneyVisitsTodayFromGrade(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/attorney/visits/last/from-grade/today?${queryParams}`)
                if (response.status === 200) {
                    this.lastAttorneyVisitsTodayFromGrade = response.data
                    return response
                }
            } catch (error) {
                return error.response
            }
        },      
    },
})