import { defineStore } from "pinia";
import { apiHttp } from '../../api/axiosApi'
export const useUserStore = defineStore("user", {
    state: () => ({
        users: {
            data: [],
            per_page: 10,
            total: 0
        },
    }),
    actions: {
        async getUsers(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/users?${queryParams}`)
                if (response.status === 200) {
                    this.users = response.data
                    return response
                }
            } catch (error) {
                return error.response
            }
        },
        async createUser(data) {
            try {
                const response = await apiHttp('POST', `/users`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async updateUser(id, data) {
            try {
                const response = await apiHttp('PUT', `/users/${id}`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async deleteUser(id) {
            try {
                const response = await apiHttp('DELETE', `/users/${id}`)
                return response
            } catch (error) {
                return error
            }
        }   
    },
})